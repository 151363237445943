@keyframes image_blur {
    0% {
        -webkit-filter: blur(10px);
        filter: blur(10px);
    };

    25% {
        -webkit-filter: blur(7.5px);
        filter: blur(7.5px);
    };

    50% {
        -webkit-filter: blur(5px);
        filter: blur(5px);
    };

    75% {
        -webkit-filter: blur(2.5px);
        filter: blur(2.5px);
    };

    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
    };
}

.ss-title-container {
    width: 100%;
    height: 10rem;
    background-color: #1e56a0;
    justify-content: center;
    align-items: center;
}

.ss-title-container > h2 {
    font-size: 4em;
    color: white;
    padding-top: 1%;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.ss-title-container > h1 {
    color: white;
    font-size: 1.2em;
}

.ss-polaroid-container {
    min-width: 100%;
    height: 90rem;
    background-color: #e7eaf6;
    position: relative;
    display: flex;
    flex-flow: wrap;
}

.ss-polaroid {
    float: left;
    width: 30%;
    height: 30rem;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
    margin-left: 3%;
    padding-top: 3.5%;
}

.ss-card {
    width: 80%;
    background-color: white;
    align-self: center;
    padding: 1rem;
    border: 0.1rem solid black;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
}

.ss-card > img {
    max-width: 100%;
    min-width: 100%;
    height: 18rem;
    object-fit:cover; 
    overflow: hidden;
    object-position: center 20%;
    animation: 0.5s ease-in 0s 1 image_blur; 
}

.ss-card-name {
    font-size: 1.5rem;
    text-align: center;
    line-height: 2em;
    font:caption;
    margin-top: 1rem;
    font-family: cursive;
}

.ss-polaroid-name {
    font: caption;
    font-size: 0.65rem;
    color: black;
}

.ss-filler-container {
    height: 5em;
    background-color: #e7eaf6;
}

@media only screen and (max-width: 350px) {
    .ss-title-container > h2 {
        font-size: 1.8em;
        padding-top: 10%;
    }

    .ss-title-container > h1 {
        font-size: 1em;
    }

    .ss-polaroid-container {
        min-width: 100%;
        height: 300rem;
        background-color: #e7eaf6;
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    .ss-polaroid {
        float: center;
        width: 100%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-top: 20%;
        margin: 0%;
        padding-right: 3%;
    }

    .ss-polaroid:nth-of-type(even) {
        background-color: #1e56a0;
    }
    
    .ss-card {
        width: 90%;
        background-color: white;
        align-self: center;
        padding: 1rem;
        border: 0.1rem solid black;
        box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    }

    .ss-polaroid-name {
        font-size: 0.5rem;
        color: black;
    }  
}

@media only screen and (min-width: 350px) {
    .ss-title-container > h2 {
        padding-top: 5%;
        font-size: 2em;
    }

    .ss-polaroid-container {
        min-width: 100%;
        height: 270rem;
        background-color: #e7eaf6;
        position: relative;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }

    .ss-polaroid {
        float: center;
        width: 100%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-top: 15%;
        padding-left: 10%;
        margin: 0%;
    }

    .ss-polaroid:nth-of-type(even) {
        background-color: #1e56a0;
    }
    
    .ss-card {
        width: 90%;
        background-color: white;
        align-self: center;
        padding: 1rem;
        border: 0.1rem solid black;
        box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    }

    .ss-polaroid-name {
        font-size: 0.65rem;
        color: black;
    }  

    .ss-filler-container {
        height: 0em;
    }
}

@media only screen and (min-width: 450px) {
    .ss-title-container > h2 {
        font-size: 2em;
        padding-top: 5%;
    }

    .ss-polaroid {
        float: center;
        width: 100%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-top: 10%;
        padding-left: 10%;
        margin: 0%;
    }

    .ss-card {
        width: 90%;
    }

    .ss-polaroid-name {
        font-size: 0.75rem;
    } 
}

@media only screen and (min-width: 600px) {
    .ss-title-container > h2 {
        font-size: 3em;
        padding-top: 3%;
    }

    .ss-polaroid-container {
        min-width: 100%;
        height: 150rem;
        background-color: #e7eaf6;
        position: relative;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
    }

    .ss-polaroid {
        float: center;
        width: 50%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        margin: 0%;
        padding-top: 7.5%;
        padding-left: 3%;
    }

    .ss-polaroid:nth-of-type(4n),
    .ss-polaroid:nth-of-type(4n-1) {
        background-color: #1e56a0;
    }

    .ss-polaroid:nth-of-type(4n-2),
    .ss-polaroid:nth-of-type(4n-3) {
        background-color: #e7eaf6;
    }
    
    .ss-card {
        width: 95%;
        background-color: white;
        align-self: center;
        padding: 1rem;
        border: 0.1rem solid black;
        box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    }

    .ss-polaroid-name {
        font-size: 0.6rem;
        color: black;
    }  

    .ss-filler-container {
        height: 0em;
    }
}

@media only screen and (min-width: 900px) {
    .ss-title-container > h2 {
        font-size: 4em;
        color: white;
        padding-top: 1%;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }
    
    .ss-title-container > h1 {
        color: white;
        font-size: 1.2em;
    }
    
    .ss-polaroid-container {
        min-width: 100%;
        height: 90rem;
        background-color: #e7eaf6;
        position: relative;
        display: flex;
        flex-flow: wrap;
    }
    
    .ss-polaroid {
        float: center;
        width: 33.33%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        margin: 0%;
        padding-top: 3.5%;
    }

    .ss-polaroid:nth-of-type(6n),
    .ss-polaroid:nth-of-type(6n-1),
    .ss-polaroid:nth-of-type(6n-2) {
        background-color: #1e56a0;
    }

    .ss-polaroid:nth-of-type(6n-3),
    .ss-polaroid:nth-of-type(6n-4),
    .ss-polaroid:nth-of-type(6n-5) {
        background-color: #e7eaf6;
    }
    
    .ss-card {
        width: 90%;
        background-color: white;
        align-self: center;
        padding: 1rem;
        border: 0.1rem solid black;
        box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    }

    .ss-polaroid-name {
        font-size: 0.65rem;
        color: black;
    }    

    .ss-filler-container {
        height: 0em;
    }
}

@media only screen and (min-width: 1200px) {    
    .ss-title-container > h2 {
        font-size: 4em;
        padding-top: 1%;
    }
    
    .ss-title-container > h1 {
        font-size: 1.2em;
    }
    
    .ss-polaroid-container {
        min-width: 100%;
        height: 90rem;
        background-color: #e7eaf6;
        position: relative;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
    }
    
    .ss-polaroid {
        float: center;
        width: 33.33%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        margin: 0%;
        padding-top: 3.5%;
    }

    .ss-polaroid:nth-of-type(6n),
    .ss-polaroid:nth-of-type(6n-1),
    .ss-polaroid:nth-of-type(6n-2) {
        background-color: #1e56a0;
    }
    
    .ss-card {
        width: 80%;
        background-color: white;
        align-self: center;
        padding: 1rem;
        border: 0.1rem solid black;
        box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    }
    
    .ss-polaroid-name {
        font-size: 0.75rem;
    }    
}
.faq-container {
    height: 65em;
    min-width: 100%;
    background-color: #1e56a0;
    position: relative;
    align-items: center;
    justify-content: center;
    padding-top: 7%;
}

.faq-container-header-box {
    margin-bottom: 2%;
}

.faq-container-header-box > h2 {
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 2em;
    padding-bottom: 5%;
    color: white;
}

.accordion-text {
    font-size: 0.8em !important;
}

.sbmh-partners-container {
    height: 300em;
    min-width: 100%;
    background-color: #e7eaf6;
    position: relative;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;
}

.sbmh-polaroid {
    float: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30em;
    padding-left: 1%;
    padding-right: 1%;
    margin-left: 0%;
    margin-right: 0%;
    padding-top: 10%;
}

.sbmh-polaroid:nth-of-type(even) {
    background-color:#1e56a0;
}

.sbmh-card {
    width: 100%;
    background-color: white;
    align-self: center;
    padding: 1em;
    border: 0.1em solid black;
    box-shadow: 0 0.2em 1.2em rgba(0,0,0,0.2);
}

.sbmh-card > img {
    max-width: 100%;
    min-width: 100%;
    height: 18em;
}

.sbmh-card-name {
    margin-top: 1em;
    border-top: 0.1em solid black;
}

.sbmh-polaroid-name {
    font-size: 0.8em;
    color: black;
}

.sbmh-filler-container {
    height: 5em;
    background-color: #e7eaf6
}

@media only screen and (min-width: 350px) {
    .faq-container {
        height: 55em;
        padding-top: 7%;
    }

    .faq-container-header-box > h2 {
        font-size: 2.2em;
        padding-bottom: 5%;
    }

    .accordion-text {
        font-size: 0.9em !important;
    }

    .sbmh-partners-container {
        height: 150em;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        background-color: #e7eaf6;
    }

    .sbmh-polaroid {
        width: 50%;
        height: 30em;
        padding-left: 1%;
        padding-right: 1%;
        margin-left: 0%;
        margin-right: 0%;
        padding-top: 10%;
    }

    .sbmh-polaroid:nth-of-type(10n),
    .sbmh-polaroid:nth-of-type(10n-1),
    .sbmh-polaroid:nth-of-type(10n-4),
    .sbmh-polaroid:nth-of-type(10n-5),
    .sbmh-polaroid:nth-of-type(10n-8),
    .sbmh-polaroid:nth-of-type(10n-9) {
        background-color: #e7eaf6
    }

    .sbmh-polaroid:nth-of-type(10n-2),
    .sbmh-polaroid:nth-of-type(10n-3),
    .sbmh-polaroid:nth-of-type(10n-6),
    .sbmh-polaroid:nth-of-type(10n-7) {
        background-color: #1e56a0;
    }

    .sbmh-filler-container {
        height: 0em;
    }
}

@media only screen and (min-width: 600px) {
    .faq-container {
        height: 45em;
        padding-top: 15%;
    }

    .faq-container-header-box > h2 {
        font-size: 2.2em;
        padding-bottom: 5%;
    }

    .accordion-text {
        font-size: 1em !important;
    }

    .sbmh-partners-container {
        height: 120em;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        background-color: #1e56a0;
    }

    .sbmh-polaroid {
        width: 33.33333333333333333333333%;
        height: 30em;
        padding-left: 1%;
        padding-right: 1%;
        margin-left: 0%;
        margin-right: 0%;
        padding-top: 5.5%;
    }

    .sbmh-polaroid:nth-of-type(10n),
    .sbmh-polaroid:nth-of-type(10n-3),
    .sbmh-polaroid:nth-of-type(10n-4),
    .sbmh-polaroid:nth-of-type(10n-5) {
        background-color: #1e56a0;
    }

    .sbmh-polaroid:nth-of-type(11n-2),
    .sbmh-polaroid:nth-of-type(11n-3),
    .sbmh-polaroid:nth-of-type(11n-4),
    .sbmh-polaroid:nth-of-type(11n-8),
    .sbmh-polaroid:nth-of-type(11n-9),
    .sbmh-polaroid:nth-of-type(11n-10) {
        background-color: #e7eaf6
    }
}

@media only screen and (min-width: 768px) {
    .faq-container {
        height: 45em;
        padding-top: 8%;
    }

    .faq-container-header-box > h2 {
        font-size: 2.7em;
        padding-bottom: 5%;
    }

    .sbmh-partners-container {
        height: 90em;
        display: flex;
        flex-flow: wrap;
        align-items: center;
        justify-content: center;
        background-color: #e7eaf6;
    }

    .sbmh-polaroid {
        width: 25%;
        height: 30em;
        padding-left: 1%;
        padding-right: 1%;
        margin-left: 0%;
        margin-right: 0%;
        padding-top: 5.5%;
    }

    .sbmh-polaroid:nth-of-type(8n+1),
    .sbmh-polaroid:nth-of-type(8n+2),
    .sbmh-polaroid:nth-of-type(8n-4),
    .sbmh-polaroid:nth-of-type(8n-5),
    .sbmh-polaroid:nth-of-type(8n-6),
    .sbmh-polaroid:nth-of-type(8n-7) {
        background-color: #e7eaf6;
    }

    .sbmh-polaroid:nth-of-type(8n),
    .sbmh-polaroid:nth-of-type(8n-1),
    .sbmh-polaroid:nth-of-type(8n-2),
    .sbmh-polaroid:nth-of-type(8n-3) {
        background-color: #1e56a0;
    }

    .sbmh-polaroid-name {
        font-size: 0.75em !important;
    }

    .sbmh-filler-container {
        height: 0em;
    }
}

@media only screen and (min-width: 992px) {
    .faq-container {
        height: 40em;
        padding-top: 2%;
    }

    .faq-container-header-box > h2 {
        font-size: 3em;
        padding-bottom: 0%;
    }
}

@media only screen and (min-width: 1200px) {
    .sbmh-polaroid-name {
        font-size: 1em !important;
    }

    .sbmh-polaroid {
        width: 25%;
        height: 30em;
        padding-left: 1%;
        padding-right: 1%;
        margin-left: 0%;
        margin-right: 0%;
        padding-top: 1.5%;
    }
}
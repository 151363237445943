.footer-container {
    height: 30em;
    min-width: 100%;
    position: relative;
    background-color: #1e56a0;
    box-shadow: 1.2rem 0.2rem 0 rgba(0,0,0,0.2);
}

.footer-col-wrapper-1 {
    width: 30%;
    height: 100%;
    float: left;
    margin-left: 2.5%;
}

.footer-col-wrapper-2 {
    width: 30%;
    height: 100%;
    float: left;
    margin-left: 2.5%;
}

.footer-col-wrapper-3 {
    width: 30%;
    height: 100%;
    float: left;
    margin-left: 2.5%;
}

.footer-col-wrapper-1 > h2 {
    font-size: 1.5em;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: white;
    padding-top: 5%;
}

.footer-col-wrapper-2 > h2 {
    font-size: 1.5em;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: white;
    padding-top: 5%;
}

.footer-col-wrapper-3 > h2 {
    font-size: 1.5em;
    font-family: Georgia, 'Times New Roman', Times, serif;
    color: white;
    padding-top: 5%;
}

.footer-col-wrapper-2 > h1 {
    font-size: 1.1em;
    color: white;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: right;
    padding-right: 8%;
}

.gmap-iframe {
    border: 0;
    padding-bottom: 5%;
    padding-top: 5%;
    width: 100%;
    height: 85%;
}

.stateline-footer-reviews {
    color: white;
    padding-top: 10%;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.social-media-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: 15%;
    display: flex;
    align-items: top;
    justify-content: center;
    padding-top: 5%;
    padding-bottom: 5%;
}

.icon-seperator {
    width: 5%;
    height: 100%;
}

@media only screen and (max-width: 300px) {
    .footer-container {
        height: 90em;
        display: flex;
        flex-flow: column;
    }

    .footer-col-wrapper-1 {
        order: 1;
        width: 100%;
        height: 30%;
        padding-right: 5%;
    }

    .footer-col-wrapper-2 {
        order: 2;
        width: 100%;
        height: 30%;
        margin-top: 5%;
        padding-right: 5%;
    }

    .footer-col-wrapper-3 {
        order: 3;
        width: 100%;
        height: 35%;
        margin-top: 35%;
        padding-right: 5%;
    }
}

@media only screen and (min-width: 300px) {
    .footer-container {
        height: 90em;
        display: flex;
        flex-flow: column;
    }

    .footer-col-wrapper-1 {
        order: 1;
        width: 100%;
        height: 30%;
        padding-right: 5%;
    }

    .footer-col-wrapper-2 {
        order: 2;
        width: 100%;
        height: 30%;
        margin-top: 15%;
        padding-right: 5%;
    }

    .footer-col-wrapper-3 {
        order: 3;
        width: 100%;
        height: 35%;
        margin-top: 25%;
        padding-right: 5%;
    }
}

@media only screen and (min-width: 350px) {
    .footer-col-wrapper-3 {
        margin-top: 15%;
    }
}

@media only screen and (min-width: 600px) {
    .footer-container {
        height: 90em;
        display: flex;
        flex-flow: column;
    }

    .footer-col-wrapper-1 {
        order: 1;
        width: 100%;
        height: 30%;
        padding-right: 7%;
    }

    .footer-col-wrapper-2 {
        order: 2;
        width: 100%;
        height: 30%;
        margin-top: 2.5%;
        padding-right: 7%;
    }

    .footer-col-wrapper-3 {
        order: 3;
        width: 100%;
        height: 30%;
        margin-top: 5%;
        padding-right: 7%;
    }
}

@media only screen and (min-width: 768px) {
    .footer-container {
        height: 30em;
        min-width: 100%;
        position: relative;
        background-color: #1e56a0;
        box-shadow: 1.2rem 0.2rem 0 rgba(0,0,0,0.2);
        display: block;
        flex-flow: none;
    }
    
    .footer-col-wrapper-1 {
        width: 30%;
        height: 100%;
        float: left;
        margin-left: 2.5%;
        margin-top: 0%;
        padding-right: 0%;
    }
    
    .footer-col-wrapper-2 {
        width: 30%;
        height: 100%;
        float: left;
        margin-left: 2.5%;
        margin-top: 0%;
        padding-right: 0%;
    }
    
    .footer-col-wrapper-3 {
        width: 30%;
        height: 100%;
        float: left;
        margin-left: 2.5%;
        margin-top: 0%;
        padding-right: 0%;
    }
    
    .footer-col-wrapper-1 > h2 {
        font-size: 1.2em;
        font-family: Georgia, 'Times New Roman', Times, serif;
        color: white;
        padding-top: 5%;
    }
    
    .footer-col-wrapper-2 > h2 {
        font-size: 1.2em;
        font-family: Georgia, 'Times New Roman', Times, serif;
        color: white;
        padding-top: 5%;
    }
    
    .footer-col-wrapper-3 > h2 {
        font-size: 1.2em;
        font-family: Georgia, 'Times New Roman', Times, serif;
        color: white;
        padding-top: 5%;
    }
    
    .footer-col-wrapper-2 > h1 {
        font-size: 0.9em;
        color: white;
        font-family: Georgia, 'Times New Roman', Times, serif;
        text-align: right;
        padding-right: 8%;
    }
    
    .gmap-iframe {
        border: 0;
        padding-bottom: 5%;
        padding-top: 5%;
        width: 100%;
        height: 85%;
    }
    
    .stateline-footer-reviews {
        color: white;
        padding-top: 10%;
        font-family: Georgia, 'Times New Roman', Times, serif;
        font-size: 0.8em;
    }

    .review-button > p {
        font-size: 0.8em;
    }
    
    .social-media-wrapper {
        position: relative;
        display: flex;
        width: 100%;
        height: 15%;
        display: flex;
        align-items: top;
        justify-content: center;
        padding-top: 5%;
        padding-bottom: 5%;
    }
    
    .icon-seperator {
        width: 5%;
        height: 100%;
    }
}

@media only screen and (min-width: 992px) {
    .footer-col-wrapper-1 > h2 {
        font-size: 1.5em;
    }
    
    .footer-col-wrapper-2 > h2 {
        font-size: 1.5em;
    }
    
    .footer-col-wrapper-3 > h2 {
        font-size: 1.5em;
    }
    
    .footer-col-wrapper-2 > h1 {
        font-size: 0.9em;
    }

    .stateline-footer-reviews {
        font-size: 0.95em;
    }

    .review-button > p {
        font-size: 1em;
    }
}

@media only screen and (min-width: 1200px) {
    .stateline-footer-reviews {
        font-size: 1.05em;
    }

    .review-button > p {
        font-size: 1em;
    }
}
.yt-video-container {
    min-width: 100%;
    height: 40em;
    position: relative;
    background-color: #1e56a0;
}

.yt-video-embed-box {
    float: left;
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yt-video-about-text-box {
    float: left;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yt-video-about-text-area {
    height: 90%;
    width: 90%;
    background-color: #e7eaf6;
    border-radius: 1em 1em 1em 1em;
    box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
}

.yt-video-about-text-area > p {
    font-size: 2em;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    margin-top: 3em;
}

.smhs-community-slideshow-container {
    height: 60em;
    min-width: 100%;
    background-color: #e7eaf6;
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.slideshow-container {
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: #e7eaf6!important;
}

.slide {
    min-width: 100%;
    max-height: 100%;
    border: none;
}

.slide img {
    width: 90%;
    height: 80%;
    max-height: 45em;
    object-fit: contain !important;
    border: none;
}

.slide iframe {
    width: 100%;
    height: 100%;
    display: block;
}

.legend {
    font-size: 1.25em;
    background-color: #1e56a0 !important;
}

.smhs-community-filler-container {
    height: 10em;
    min-width: 100%;
    background-color: #e7eaf6;
}

@media only screen and (max-width: 600px) {
    .yt-video-container {
        min-width: 100%;
        height: 50em;
        position: relative;
        background-color: #1e56a0;
    }
    
    .yt-video-embed-box {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .yt-video-about-text-box {
        float: left;
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .yt-video-about-text-area > p {
        font-size: 1.7em;
        font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        margin-top: 1em;
    }

    .legend {
        font-size: 0.6em !important;
    }
}

@media only screen and (min-width: 600px) {
    .yt-video-container {
        min-width: 100%;
        height: 60em;
        position: relative;
        background-color: #1e56a0;
    }
    
    .yt-video-embed-box {
        width: 100%;
        height: 67%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .yt-video-about-text-box {
        float: left;
        width: 100%;
        height: 33%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .yt-video-about-text-area > p {
        font-size: 1.7em;
        font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        margin-top: 1em;
    }

    .legend {
        font-size: 0.8 !important;
    }
}

@media only screen and (min-width: 768px) {
    .yt-video-container {
        min-width: 100%;
        height: 40em;
        position: relative;
        background-color: #1e56a0;
    }
    
    .yt-video-embed-box {
        float: left;
        width: 60%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .yt-video-about-text-box {
        float: left;
        width: 40%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .yt-video-about-text-area {
        height: 90%;
        width: 90%;
        background-color: #e7eaf6;
        border-radius: 1em 1em 1em 1em;
        box-shadow: 0 0.2rem 1.2rem rgba(0,0,0,0.2);
    }
    
    .yt-video-about-text-area > p {
        font-size: 1.8em;
        font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
        margin-top: 3em;
    }
    
    .smhs-community-slideshow-container {
        height: 60em;
        min-width: 100%;
        background-color: #e7eaf6;
        padding-left: 2%;
        padding-right: 2%;
        padding-top: 2%;
        padding-bottom: 2%;
    }
    
    .slideshow-container {
        width: 100%;
        max-width: 100%;
        height: 100%;
        background: #e7eaf6;
    }
    
    .slide {
        min-width: 100%;
        max-height: 100%;
        border: none;
    }
    
    .slide img {
        width: 90%;
        height: 80%;
        max-height: 45em;
        object-fit: contain !important;
        border: none;
    }
    
    .legend {
        font-size: 1.25em !important;
        background-color: #1e56a0;
    }
    
    .smhs-community-filler-container {
        height: 10em;
        min-width: 100%;
        background-color: #e7eaf6;
    }
}

@media only screen and (min-width: 992px) {
    .yt-video-about-text-area > p {
        font-size: 1.8em;
        margin-top: 3em;
    }

    .legend {
        font-size: 1.5em !important;
    }
}

@media only screen and (min-width: 1200px) {
    .yt-video-embed-box {
        width: 70%;
    }
    
    .yt-video-about-text-box {
        width: 30%;
    }

    .yt-video-about-text-area > p {
        font-size: 2em;
        margin-top: 3em;
    }
}
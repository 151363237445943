@keyframes image_blur {
    0% {
        -webkit-filter: blur(10px);
        filter: blur(10px);
    };

    25% {
        -webkit-filter: blur(7.5px);
        filter: blur(7.5px);
    };

    50% {
        -webkit-filter: blur(2.5px);
        filter: blur(5px);
    };

    75% {
        -webkit-filter: blur(2.5px);
        filter: blur(2.5px);
    };

    100% {
        -webkit-filter: blur(0px);
        filter: blur(0px);
    };
}

.title-container {
    width: 100%;
    height: 10rem;
    background-color: #1e56a0;
    justify-content: center;
    align-items: center;
}

.title-container>h2 {
    color: white;
    font-size: 1.5em;
    padding-top: 10%;
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.title-container>h1 {
    color: white;
    font-size: 1em;
}

.polaroid-container {
    height: 780rem;
    position: relative;
    min-width: 100%;
    background-color: #e7eaf6;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.polaroid {
    float: left;
    width: 25%;
    height: 30rem;
    justify-content: center;
    align-items: center;
    padding-left: 3.5%;
    padding-top: 3%;
    background-color: #e7eaf6;
}

.polaroid:nth-of-type(even) {
    float: center;
    width: 100%;
    height: 30rem;
    padding-top: 13%;
    justify-content: center;
    background-color: #1e56a0;
}

.polaroid:nth-of-type(odd) {
    float: center;
    width: 100%;
    height: 30rem;
    padding-top: 13%;
    justify-content: center;
    background-color: #e7eaf6;
}

.card-front {
    width: 95%;
    background-color: white;
    align-self: center;
    padding: 1rem;
    border: 0.1rem solid black;
    box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
}

.card-front>img {
    max-width: 100%;
    min-width: 100%;
    height: 18rem;
    object-fit:cover; 
    overflow: hidden;
    object-position: center 15%;
    animation: 0.5s ease-in 0s 1 image_blur; 
}

.card-front-name {
    text-align: center;
    line-height: 2em;
    margin-top: 1rem;
}

.polaroid-name {
    font-size: 0.7rem;
    color: black;
}

.ot-filler-container {
    height: 5em;
    background-color: #e7eaf6;
}

#modal-bio-text {
    font-size: 8px;
}

@media only screen and (min-width: 450px) {
    .polaroid-container {
        height: 900rem;
        display: flex;
        flex-flow: none;
        align-items: none;
        justify-content: none;
    }

    .card-front {
        width: 85%;
    }

    .polaroid {
        padding-left: 15%;
        padding-top: 10%;
    }

    .polaroid:nth-of-type(even) {
        float: center;
        width: 100%;
        height: 30rem;
        padding-left: 15%;
        padding-top: 10%;
        justify-content: center;
        background-color: #1e56a0;
    }

    .polaroid:nth-of-type(odd) {
        float: center;
        width: 100%;
        height: 30rem;
        padding-left: 15%;
        padding-top: 10%;
        justify-content: center;
        background-color: #e7eaf6;
    }

    .ot-filler-container {
        height: 0em;
        background-color: #e7eaf6;
    }

    #modal-bio-text {
        font-size: 10px;
    }

    .ot-filler-container {
        height: 5em;
    }
}

@media only screen and (min-width: 600px) {
    .polaroid-container {
        height: 450rem;
        display: flex;
        flex-flow: wrap;
    }

    .title-container > h2 {
        font-size: 2.5em;
        padding-top: 3.5%;
    }

    .polaroid-container {
        padding: 0%;
    }

    .polaroid {
        float: center;
        width: 50%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 1.5%;
        padding-right: 1.5%;
        padding-top: 5%;
        background-color: #e7eaf6;
    }

    .polaroid:nth-of-type(4n),
    .polaroid:nth-of-type(4n-1) {
        float: center;
        width: 50%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 5%;
        padding-top: 8%;
        background-color: #1e56a0;
    }

    .polaroid:nth-of-type(4n-2),
    .polaroid:nth-of-type(4n-3) {
        float: center;
        width: 50%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 5%;
        padding-top: 5%;
        background-color:#e7eaf6;
    }

    .card-front {
        width: 90%;
    }

    .polaroid-name {
        font-size: 0.8em;
    }

    #modal-bio-text {
        font-size: 12px;
    }

    .ot-filler-container {
        height: 0em;
    }
}

@media only screen and (min-width: 768px) {
    .title-container > h2 {
        font-size: 3em;
        color: white;
        padding-top: 3%;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }

    .polaroid:nth-of-type(4n),
    .polaroid:nth-of-type(4n-1) {
        padding-left: 5%;
        padding-right: 1.5%;
        padding-top: 6.5%;
    }

    .polaroid:nth-of-type(4n-2),
    .polaroid:nth-of-type(4n-3) {
        padding-left: 5%;
        padding-right: 1.5%;
        padding-top: 6.5%;
    }

    .polaroid-name {
        font-size: 0.8em;
    }

    .ot-filler-container {
        height: 0em;
    }
}

@media only screen and (min-width: 900px) {
    .title-container > h2 {
        font-size: 4em;
        padding-top: 1.5%;
    }

    .polaroid-container {
        height: 300rem;
        min-width: 100%;
        position: relative;
        background-color: #e7eaf6;
        display: flex;
        flex-flow: wrap;
    }

    .polaroid {
        float: center;
        width: 33.33%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 4.5%;
        padding-top: 5%;
        background-color: #e7eaf6;
    }

    .polaroid:nth-of-type(6n),
    .polaroid:nth-of-type(6n-1),
    .polaroid:nth-of-type(6n-2) {
        float: left;
        width: 33.33%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 3.5%;
        padding-top: 5%;
        background-color: #1e56a0;
    }

    .polaroid:nth-of-type(6n-3),
    .polaroid:nth-of-type(6n-4),
    .polaroid:nth-of-type(6n-5) {
        float: left;
        width: 33.33%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 3.5%;
        padding-top: 5%;
        background-color: #e7eaf6;
    }

    .polaroid-name {
        font-size: 0.7rem;
    }

    #modal-bio-text {
        font-size: 14px;
    }

    .ot-filler-container {
        height: 5em;
    }
}

@media only screen and (min-width: 1200px) {
    .header-container {
        min-width: 100%;
        height: 4rem;
    }
    
    .title-container {
        width: 100%;
        height: 10rem;
        background-color: #1e56a0;
        justify-content: center;
        align-items: center;
    }
    
    .title-container>h2 {
        font-size: 4em;
        color: white;
        padding-top: 1%;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }
    
    .title-container>h1 {
        color: white;
        font-size: 1.2em;
    }
    
    .polaroid-container {
        height: 240rem;
        position: relative;
        min-width: 100%;
        background-color: #e7eaf6;
    }
    
    .polaroid {
        float: left;
        width: 25%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 2.5%;
        padding-top: 3%;
        background-color: #e7eaf6;
    }
    
    .polaroid:nth-of-type(4n),
    .polaroid:nth-of-type(4n-1),
    .polaroid:nth-of-type(4n-2),
    .polaroid:nth-of-type(4n-3) {
        float: left;
        width: 25%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 2.5%;
        padding-top: 3%;
        background-color: #e7eaf6;
    }

    .polaroid:nth-of-type(8n),
    .polaroid:nth-of-type(8n-1),
    .polaroid:nth-of-type(8n-2),
    .polaroid:nth-of-type(8n-3) {
        float: left;
        width: 25%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 2.5%;
        padding-top: 3%;
        background-color: #1e56a0;
    }

    .polaroid:nth-of-type(28n) {
        float: left;
        width: 25%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 2.5%;
        padding-right: 1.5%;
        padding-top: 3%;
        background-color: #e7eaf6;
    }

    .polaroid:nth-of-type(29n) {
        float: left;
        width: 50%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 18%;
        padding-right: 10%;
        padding-top: 3%;
        background-color: #1e56a0;
    }

    .polaroid:nth-of-type(30n) {
        float: left;
        width: 50%;
        height: 30rem;
        justify-content: center;
        align-items: center;
        padding-left: 10%;
        padding-right: 18%;
        padding-top: 3%;
        background-color: #1e56a0;
    }
    
    .card-front {
        width: 95%;
        background-color: white;
        align-self: center;
        padding: 1rem;
        border: 0.1rem solid black;
        box-shadow: 0 0.2rem 1.2rem rgba(0, 0, 0, 0.2);
    }
    
    .card-front-name {
        text-align: center;
        line-height: 2em;
        margin-top: 1rem;
    }
    
    .polaroid-name {
        font-size: 0.8rem;
        color: black;
    }
}